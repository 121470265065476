<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'EventsList',
  components: {
    Action: () => import('@/components/general/Action'),
    Dropdown: () => import('@/components/general/Dropdown'),
    Datatable: () => import('@/components/general/Datatable'),
    FilterList: () => import('@/components/general/FilterList'),
    Pagination: () => import('@/components/general/Pagination'),
    DropdownLink: () => import('@/components/general/DropdownLink'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    FilterListSearch: () => import('@/components/general/FilterListSearch'),
    ModalConfirm: () => import('@/components/macro/ModalConfirm'),
    Tabs: () => import('@/components/general/Tabs')
  },

  data () {
    return {
      pagination: {
        query: {},
        filter: {},
        order: {},
        limit: 11,
        page: 1,
        lastPage: null
      },

      modalRemoveOpen: false,
      modalDeactivateOpen: false,
      eventId: null,
      eventsList: [],

      tabLinks: [
        {
          location: { name: 'events.index' },
          text: this.$t('events.tabs:link.1')
        },
        {
          location: { name: 'agendas.index' },
          text: this.$t('events.tabs:link.2')
        }
      ]
    }
  },

  computed: {
    ...mapGetters([
      'getEventPagination',
      'activeModules'
    ]),

    canWrite () {
      return this.getContextPermission('events') === 'write'
    }
  },

  watch: {
    'pagination.page' () {
      this.getEventList()
    }
  },

  created () {
    // TODO: Remover reload forçado para funcionar o goBack de viewAgendas
    if (this.$route.params.refresh === true) {
      window.location.reload()
      this.$route.params.refresh = false
    }

    this.pagination = this.getEventPagination
    this.getEventList()
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'setEventPagination',
      'attemptGetEventsList',
      'attemptManageEvent',
      'attemptRemoveEvent',
      'attemptToggleActiveEvent'
    ]),

    prevPage () {
      if (this.pagination.page > 1) {
        this.pagination.page -= 1
      }
    },

    nextPage () {
      this.pagination.page += 1
    },

    firstPage () {
      this.pagination.page = 1
    },

    lastPage () {
      this.pagination.page = this.pagination.lastPage
    },

    createNewEvent () {
      this.attemptManageEvent(null)
      this.$router.push({ name: 'events.manage' })
    },

    searchEvent (search) {
      this.pagination.query = {
        name: search,
        only_name: true
      }

      if (this.pagination.page === 1) {
        this.getEventList()
      } else {
        this.pagination.page = 1
      }
    },

    edit (eventId, tab = 1) {
      this.setFetching(true)

      this.attemptManageEvent(eventId).then(() => {
        this.$router.push({
          name: 'events.manage',
          params: {
            id: eventId,
            tab: tab
          }
        })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    toggleActive (eventId, toggleFlag) {
      this.setFetching(true)

      this.attemptToggleActiveEvent(eventId).then(() => {
        this.getEventList()

        if (!toggleFlag) {
          this.setFeedback({ message: this.$t('events:feedback.toggle.activate.success') })
        } else {
          this.setFeedback({ message: this.$t('events:feedback.toggle.deactivate.success') })
        }
      }).catch((e) => {
        if (e.data.error.message === 'event__event_has_active_schedules') {
          this.setFeedback({ message: this.$t('events:feedback.schedules.active') })
        } else {
          this.setFeedback({ message: this.$t('global.error') })
        }
      }).finally(() => {
        this.setFetching(false)
        this.modalDeactivateOpen = false
      })
    },

    deactivate () {
      this.toggleActive(this.eventId, true)
    },

    confirmDeactivate (idEvent) {
      this.eventId = idEvent
      this.modalDeactivateOpen = true
    },

    cancelDeactivate () {
      this.modalDeactivateOpen = false
    },

    doSearch () {
      return this.getEventPagination && this.getEventPagination.query ? this.getEventPagination.query.name : null
    },

    getEventList () {
      this.setFetching(true)
      this.setEventPagination(this.pagination)

      this.attemptGetEventsList(this.pagination).then(pagination => {
        this.pagination.lastPage = pagination.lastPage
        this.eventsList = pagination.data
      }).finally(() => {
        this.setFetching(false)
      })
    },

    addAgenda (eventId) {
      this.$router.push({
        name: 'agendas.manage',
        params: {
          event: eventId
        }
      })
    },

    viewEventAgendas (event) {
      this.$router.push({
        name: 'events.view.agendas',
        params: {
          id: event.id,
          name: event.name
        }
      })
    }
  }
}
</script>

<template>
  <div class="main-content">
    <ContentHeader
      :title="$t('events:header.title')"
      :description="$t('events:header.description')"
      background="/assets/images/themes/default/png/events_banner.png"
    >
      <Tabs
        slot="tabs"
        dark
        :links="tabLinks"
      />
    </ContentHeader>

    <FilterList>
      <Action
        v-if="canWrite"
        slot="button"
        primary
        large
        fixed-width
        type="button"
        :text="$t('events.list:btn.add')"
        @click="createNewEvent"
      />

      <FilterListSearch
        slot="search"
        :hide-error-details="true"
        :do-search="doSearch()"
        @search="searchEvent"
      />
    </FilterList>

    <div
      v-if="eventsList && eventsList.length > 0"
      class="center events-table"
    >
      <Datatable :items="eventsList">
        <template slot="thead">
          <tr>
            <th
              class="th"
              width="370"
            >
              {{ $t('events.list:datatable.header.1') }}
            </th>

            <th class="th text-center">
              {{ $t('events.list:datatable.header.2') }}
            </th>

            <th class="th text-center">
              {{ $t('events.list:datatable.header.3') }}
            </th>

            <th
              v-if="canWrite"
              class="th"
              width="40"
            />
          </tr>
        </template>

        <template
          slot="tbody"
          slot-scope="props"
        >
          <tr>
            <td class="td">
              <span class="td-text bolder">
                {{ props.item.name }}
              </span>
            </td>

            <td
              class="td text-center"
              width="200"
            >
              <span class="td-text bolder">
                {{ props.item.totalActiveSchedule }}/{{ props.item.totalSchedule }}
              </span>
            </td>

            <td
              class="td text-center"
              width="80"
            >
              <span
                v-if="!!props.item.active"
                class="td-tag active-ribbon"
              >{{ $t('global.status:active') }}</span>

              <span
                v-else
                class="td-tag inactive-ribbon"
              >{{ $t('global.status:inactive') }}</span>
            </td>

            <td
              v-if="canWrite"
              class="td td-actions"
              width="40"
            >
              <Dropdown
                icon="dots-vertical"
                right
              >
                <DropdownLink
                  :text="$t('global:event.edit')"
                  @click="edit(props.item.id, 1)"
                />

                <DropdownLink
                  v-if="props.item.active"
                  :text="$t('global:agenda.add')"
                  @click="addAgenda(props.item.id)"
                />

                <DropdownLink
                  v-if="props.item.totalSchedule > 0"
                  :text="$t('global:agenda.view')"
                  @click="viewEventAgendas(props.item)"
                />

                <DropdownLink
                  v-if="!props.item.active"
                  :text="$t('global:activate')"
                  @click="toggleActive(props.item.id, props.item.active)"
                />

                <DropdownLink
                  v-if="props.item.active"
                  :text="$t('global:deactivate')"
                  @click="confirmDeactivate(props.item.id)"
                />
              </Dropdown>
            </td>
          </tr>
        </template>
      </Datatable>

      <Pagination
        :active-page="pagination.page"
        :page-count="pagination.lastPage"
        @firstPage="firstPage"
        @lastPage="lastPage"
        @nextPage="nextPage"
        @previousPage="prevPage"
      />
    </div>

    <div
      v-else
      class="center events-table"
    >
      <p>Nenhum evento encontrado para o termo pesquisado</p>
    </div>

    <ModalConfirm
      :active="modalDeactivateOpen"
      :title="$t('events.manage:deactivate.title')"
      :description="$t('events.manage:deactivate.description')"
      @confirmAction="deactivate"
      @cancelAction="cancelDeactivate"
    />
  </div>
</template>

<style>
.tooltip {
  color: #005EB8;
  z-index: 9999;
  font-size: 0.85rem;
  margin: 1px solid #005EB8;
}

.tooltip p {
  margin-top: 5px;
}

.tooltip.info .tooltip-inner, .popover-inner div {
  background: linear-gradient(to bottom, #00B1E6 0%,#00426E 100%);
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, .1);
  max-width: 500px;
  color: white;
}

.popover-inner div p {
  padding-bottom: 10px;
}
.popover-inner div span {
  display: block;
}

.tooltip .info .tooltip-arrow {
  border-color: #005EB8;
}

.warning {
  cursor: pointer;
  margin-left: 10px;
}

.form-buttons {
  display: flex;
  justify-content: space-around;
}
</style>
